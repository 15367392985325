import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.css'
import './assets/css/font.css'
import axios from 'axios'
import fastclick from 'fastclick'
// // 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'
// import Charts from '@jiaminghi/charts'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import "echarts-gl";
// Vue.use(dataV)
import moment from "moment";
Vue.prototype.$moment = moment
moment.locale('zh-cn')




// import cookies from 'vue-cookies'
// Vue.prototype.$cookies = cookies;

// import $ from 'jquery'
// 注册组件后即可使用
Vue.component('v-chart', echarts)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
fastclick.attach(document.body);

// Vue.aa= 'http://47.113.111.247:80'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
