import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../layout/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'Home',
      meta: {requireAuth: true},
      components: {
        default: () => import('@/views/home/index')
      },
      redirect:'/home',
      children:[
              {
                name: '首页',
                path: '/home',
                meta: {
                    footer: false,
                    keepAlive: true,
                },
                component: () =>
                    import('../views/home/index.vue')
              },
          ]
    },

    {
      path: '/roomprofile',
      name: '领域简介',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/info/showroom2.vue')
    },
    {
      path: '/aboutmessage',
      name: '合作留言',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/info/about1.vue')
    },
    {
      path: '/aboutphone',
      name: '联系方式',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/info/about2.vue')
    },
    {
      path: '/resultsinfo',
      name: '成果信息',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/resultsinfo/index.vue')
    },
    {
      path: '/resultallDetails',
      name: '详细页',
      meta: {
          keepAlive: false,
      },
      component: () => import('../views/resultsinfo/alldetails.vue')
    },
    {
      path: '/resultDetails',
      name: '详细页',
      meta: {
          keepAlive: false,
      },
      component: () => import('../views/resultsinfo/details.vue')
    },
    {
      path: '/parkinfo',
      name: '园企产品',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/resultsinfo/park.vue')
    },
    {
      path: '/serviceinfo',
      name: '技术服务',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/resultsinfo/service.vue')
    },
    // {
    //   path: '/interactinfo',
    //   name: '供需交流',
    //   meta: {
    //       keepAlive: true,
    //   },
    //   component: () => import('../views/resultsinfo/interact.vue')
    // },
    {
      path: '/prelease',
      name: '个人发布',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/resultsinfo/prelease.vue')
    },

    {
      path: '/pdetails',
      name: '详情',
      meta: {
          keepAlive: false,
      },
      component: () => import('../views/resultsinfo/pdetails.vue')
    },




    {
      path: '/team',
      name: '概况信息',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/team/index.vue')
    },
    {
      path: '/teamExpert',
      name: '特聘专家',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/team/teamexpert.vue')
    },
    {
      path: '/organList',
      name: '科创机构',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/team/organlist.vue')
    },
    {
      path: '/parklist',
      name: '园区企业',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/team/parklist.vue')
    },
    {
      path: '/teamList',
      name: '创新团队',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/team/teamlist.vue')
    },
    {
      path: '/teamDetails',
      name: '详细页',
      meta: {
          keepAlive: false,
      },
      component: () => import('../views/team/details.vue')
    },

    // 配置普通登录路由
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/index.vue')
    },
    {
      path: '/register',
      name: '注册',
      component: () => import('../views/login/register.vue')
    },
    {
      path: '/forgetpwd',
      name: '忘记密码',
      component: () => import('../views/login/forgetpwd.vue')
    },


    {
       path: '/notice',
       name: '通知公告',
       meta: {
           keepAlive: true,
       },
       component: () => import('../views/info/news1.vue')
    },
    {
       path: '/news',
       name: '成果新闻',
       meta: {
           keepAlive: true,
       },
       component: () => import('../views/info/news2.vue')
    },
    {
       path: '/aboutus',
       name: '综合相关政策',
       meta: {
           keepAlive: true,
       },
       component: () => import('../views/info/news3.vue')
    },
    {
       path: '/newsdetails',
       name: '详情页',
       meta: {
           keepAlive: false,
       },
       component: () => import('../views/info/details.vue')
    },


    {
      path: '/about',
      name: '关于我们',
      meta: {
          keepAlive: true,
      },
      component: () => import('../views/info/about.vue')
    },


    ];



    const originalPush = VueRouter.prototype.push
    VueRouter.prototype.push = function push (location) {
        return originalPush.call(this, location).catch(err => err)
    }

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,

})



export default router
